import Link from 'next/link'

const Error404 = () => {
  return (
    <div className="flex h-screen min-w-[360px] items-center justify-center bg-white">
      <div className="mx-auto max-w-7xl">
        <div className="mx-auto">
          <div className="flex flex-col items-center justify-center gap-7">
            <div className="w-55 md:w-60">
              <img src="https://d35ai18pny966l.cloudfront.net/course/404_img.png" alt="404" />
            </div>
            <div className="flex flex-col">
              <p className="text-center text-2xl font-semibold">
                링크가 만료되었거나
                <br className="block md:hidden" /> 존재하지 않는 페이지입니다.
              </p>
              <span className="mt-1.5 text-center text-label-neutral">
                요청하신 페이지를 찾을 수 없어요.
                <br />
                입력하신 주소를 다시 한번 확인해주세요.
              </span>
              <Link href={'/'}>
                <a className="mt-6 inline-flex items-center justify-center self-center rounded-lg bg-primary px-10 py-2.5 text-white">
                  <span className="text-base font-semibold">홈페이지로 돌아가기</span>
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Error404
